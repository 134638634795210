import React, {useEffect, useState} from "react";
import ModalTemplate from '../base/ModalTemplate';
import MultiSelect from "../base/MultiSelect";
import Button from "../base/Button";
import {useDispatch, useSelector} from "react-redux";
import api from "../../api";
import {useParams} from "react-router-dom";
import {updateApplicantTags} from "../../redux/slices/jobsSlice";
import {getSubscriberRole, getUserCompanyId} from "../../redux/slices/applicationSlice";
import {toast} from "react-toastify";
import CreatableSelect from 'react-select/creatable';
import {components} from 'react-select';


const CreatableMultiSelect = ({
                                  options,
                                  value,
                                  placeholder = '',
                                  showRemoveButton = true,
                                  onChange,
                              }) => {
    const Placeholder = () => {
        return (
            <div className="placeholder">{placeholder}</div>
        );
    };

    let selectComponents = {
        DropdownIndicator: () => null,
        MultiValueContainer: ({children, ...props}) => {
            return (
                <components.MultiValueContainer {...props} className="select-multi-value-container">
                    {children}
                </components.MultiValueContainer>
            )
        },
        Input: ({children, ...props}) => {
            return (
                <components.Input {...props} className='select-multi-input-wrapper' placeholder={placeholder}>
                    {children}
                </components.Input>
            )
        },
        MultiValueRemove: ({children, ...props}) => (
            showRemoveButton ?
                <components.MultiValueRemove {...props}>{children}</components.MultiValueRemove>
                : null
        ),
        MultiValue: ({children, ...props}) => {
            return (
                <components.MultiValue {...props} className="select-multi-value">
                    {children}
                </components.MultiValue>
            )
        },
        IndicatorSeparator: () => null,
        IndicatorsContainer: () => null,
    };

    let classNames = {
        control: (e) => {
            return `select-selection ${e.menuIsOpen ? 'select-selection-focused' : ''} ${e.getValue().value || value ? 'has-value' : ''}`
        },
        singleValue: (e) => {
            return e.getValue()?.value ? 'aaa has-value' : 'aaa';
        },
        valueContainer: () => 'bbb',
        menu: () => 'ccc select2-dropdown select2-dropdown--below',
        container: () => 'ddd'
    }

    return (
        <CreatableSelect
            isMulti
            options={options}
            classNames={classNames}
            placeholder={placeholder}
            value={value}
            data-value="customData1"
            components={selectComponents}
            onChange={onChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && e.target.value) {
                    e.preventDefault();
                }
            }}
            onCreateOption={(inputValue) => {
                const newOption = {value: inputValue, label: inputValue};
                onChange([...(value || []), newOption]);
            }}
        />
    )
};


const AddTagModal = ({
                         open,
                         handleClose,
                         modalSize,
                         footerBorderTop = true,
                         data,
                         selectedApplicants = []
                     }) => {
    const {job} = useSelector(state => state.jobs);
    const subscriberRole = useSelector(getSubscriberRole);
    const dispatch = useDispatch();
    const {jobId} = useParams();
    const [applicants, setApplicants] = useState(
        selectedApplicants?.map(one => ({...one, value: one.email, label: one.fullName}))
    );
    const [options, setOptions] = useState([]);
    const [tags, setTags] = useState([]);
    const companyId = useSelector(getUserCompanyId);


    const fetchTags = async () => {
        try {
            const resTags = await api.companies.getApplicantsTags(companyId);
            if (resTags?.data.length) {
                setOptions(resTags?.data?.map(tag =>  ({label: tag?.name, value: tag?.name})) || [])
            }
        } catch (e) {
            console.error("Failed to fetch tags:", e);
        }
    };

    useEffect(() => {
        if (companyId) fetchTags();
    }, [companyId]);


    useEffect(() => {
        if (!selectedApplicants || selectedApplicants.length === 0) return;

        if (selectedApplicants.length === 1) {
            const {tags: contenderTags} = selectedApplicants[0];
            if (contenderTags) {
                setTags(contenderTags.map(tag => ({
                    label: tag,
                    value: tag
                })));
            }
        } else {
            setTags([]);
        }
    }, [selectedApplicants]);

    const handleSubmit = async () => {
        if (!applicants || applicants.length === 0) {
            toast.error("No applicants selected.");
            return;
        }

        const selectedTags = tags.map(({value}) => value);

        if (applicants.length > 1) {
            await Promise.all(applicants.map(async (applicant) => {
                const resp = await api.jobApplications.updateApplication(jobId, applicant.id, {
                    tags: selectedTags
                });
                if (resp.success) {
                    dispatch(updateApplicantTags(resp.data));
                }
            }));
            toast.success("Tags updated for all selected applicants");
        } else {
            const applicantId = applicants[0].id;
            const resp = await api.jobApplications.updateApplication(jobId, applicantId, {
                tags: selectedTags
            });
            if (!resp.success) {
                toast.error("Failed to update tags");
                return;
            }
            dispatch(updateApplicantTags(resp.data));
            toast.success("Tags updated successfully");
        }

        handleClose();
    };

    return (
        <ModalTemplate open={open} modalSize={modalSize} onClick={handleClose}>
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">ADD TAG</h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                {selectedApplicants?.length > 1 && (
                    <div className="flex gap-[14px] flex-col items-start w-full mb-6">
                        <p className="flex items-center min-w-[140px] text-[20px] md:min-h-[26px] font-semibold text-gray-400 leading-[18px]">
                            Applicants
                        </p>
                        <div className="select-multi-value-container--secondary">
                            <MultiSelect
                                options={selectedApplicants?.map(one => ({
                                    ...one,
                                    label: one.fullName,
                                    value: one.email
                                }))}
                                selectStyle="secondary"
                                placeholder='Add applicants'
                                value={applicants}
                                onChange={val => {
                                    setApplicants(val)
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="w-full">
                    <div className="flex flex-col gap-[16px] mb-[32px]">
                        <p className="text-gray-400 text-[20px]">
                            To attach a special tag to this customer, type a tag name and press enter or select from
                            available tags
                        </p>
                        <div className="select-multi-value-container--secondary">
                            <CreatableMultiSelect
                                options={options}
                                selectStyle="secondary"
                                placeholder='Add a tag'
                                value={tags}
                                onChange={(val) => {
                                    setTags(val);
                                }}
                                isClearable
                                isMulti
                            />
                        </div>
                    </div>
                </div>
            </div>
            {subscriberRole !== "interviewer" &&
                <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                    <Button className={'text-gray-400 min-w-[122px] min-h-[52px] text-[18px]'} onClick={handleClose}>
                        <span className="underline">Cancel</span>
                    </Button>
                    <Button onClick={handleSubmit}
                            className={'text-white bg-green-500 min-w-[122px] min-h-[52px] text-[18px]'}>
                        Submit
                    </Button>
                </div>}
        </ModalTemplate>
    )
};

export default AddTagModal;
