import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ModalTemplate from '../base/ModalTemplate';
import InputFieldHorisontal from '../base/InputFieldHorisontal';
import Button from "../base/Button";
import TextareaFieldHorisontal from '../base/TextareaFieldHorisontal';
import PageLoader from "../PageLoader";
import api from "../../api";
import {getUser, getUserCompanyId} from "../../redux/slices/applicationSlice";
import {Tooltip} from 'react-tooltip';
import SelectBox from "../base/SelectBox";
import {toast} from "react-toastify";

const VARIABLES = [
    {"key": "applicant_fullname", "description": "Full name of the job applicant."},
    {"key": "applicant_email", "description": "Email address of the job applicant."},
    {"key": "applicant_phone", "description": "Phone number of the job applicant."},
    {"key": "job_name", "description": "Title of the job the applicant applied for."},
    {"key": "job_timezone", "description": "Timezone associated with the job."},
    {"key": "job_country", "description": "Country where the job is located."},
    {"key": "job_address", "description": "Street address of the job location."},
    {"key": "job_city", "description": "City where the job is located."},
    {"key": "job_state", "description": "State or region where the job is located."},
    {"key": "job_zipCode", "description": "Postal or ZIP code of the job location."},
    {"key": "job_description", "description": "Description of the job role or position."},
    {"key": "company_name", "description": "Name of the company offering the job."},
    {"key": "company_size", "description": "Size of the company."},
    {"key": "company_description", "description": "Brief description of the company's activities or mission."}
];


const AddTemplateModal = ({open, handleClose, footerBorderTop = true, role, onSubmitClick, addItem}) => {
    const companyId = useSelector(getUserCompanyId);
    const user = useSelector(getUser);

    const [formData, setFormData] = useState({
        type: "email",
        title: "",
        subject: "",
        content: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [isVariablesVisible, setIsVariablesVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingTemplateId, setEditingTemplateId] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (companyId) {
            fetchTemplates();
        }
    }, [companyId]);

    const fetchTemplates = async () => {
        try {
            const resTemplates = await api.communications.getAllTemplates(companyId, "", "&isOwned=true");
            setTemplates(resTemplates?.data || []);
        } catch (e) {
            console.error("Failed to fetch templates:", e);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setFormErrors(prevErrors => ({...prevErrors, [name]: ''}));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.content) {
            newErrors.content = "Content empty";
        }
        if (!formData.title) {
            newErrors.title = "Title empty";
        }
        if (!formData.subject) {
            newErrors.subject = "Subject empty";
        }
        setFormErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async () => {
        if (!validateForm()) return;
        setIsLoading(true);
        let response;
        if (isEditing && editingTemplateId) {
            response = await api.communications.updateTemplate(companyId, editingTemplateId, formData);
        } else {
            response = await api.communications.createTemplate(companyId, formData);
        }
        setIsLoading(false);
        if (response.success) {
            if (isEditing) {
                setTemplates((prev) =>
                    prev.map((t) => (t.id === editingTemplateId ? response.data : t))
                );
            } else {
                setTemplates((prev) => [...prev, response.data]);
                addItem && addItem({...response.data, role});
            }
            setFormData({type: "email", title: "", content: "", subject: ""});
        } else {
            if (response?.errors?.length) {
                response.errors.forEach((error) => {
                    toast.error(`${error?.field}: ${error?.msg}`);
                });
            } else {
                toast.error(response?.message);
            }
        }
    };

    const handleEdit = (template) => {
        setFormData({
            type: template.type || "email",
            title: template.title || "",
            content: template.content || "",
            subject: template.subject || ""
        });
        setIsEditing(true);
        setEditingTemplateId(template.id);
    };

    const handleDelete = async (templateId) => {
        const res = await api.communications.deleteTemplate(companyId, templateId);
        if (res.success) {
            setTemplates(prev => prev.filter(t => t.id !== templateId));
        }
    };

    const handleCloseModal = () => {
        setFormData({type: "email", title: "", content: ""});
        setIsEditing(false);
        setEditingTemplateId(null);
        handleClose();
    };

    const types = [
        {label: "SMS", value: "sms"},
        {label: "Email", value: "email"},
    ];

    return (
        <ModalTemplate open={open} onClick={handleCloseModal}>
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">
                    Templates
                </h2>
                <button className="btn btn-close" onClick={handleCloseModal}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                <div className="w-full">
                    {templates.length > 0 && (
                        <div className="mb-[32px]">
                            <h3 className="text-[20px] font-semibold mb-[12px]">Existing Templates</h3>
                            <div className="overflow-auto max-h-[250px]">
                                <table className="table-auto w-full text-center border border-gray-200 rounded">
                                    <thead>
                                    <tr>
                                        <th className="font-semibold px-4 py-3 border-b text-start">Title</th>
                                        <th className="font-semibold px-4 py-3 border-b text-start">Type</th>
                                        <th className="font-semibold px-4 py-3 border-b text-start">Content</th>
                                        <th className="font-semibold px-4 py-3 border-b text-end">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {templates?.map(template => {
                                        const tooltipId = `template-content-${template.id}`;
                                        return (
                                            <tr key={template.id} className="border-b text-start">
                                                <td className="px-4 py-3 align-text max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                                                    <strong>{template.title}</strong>
                                                </td>
                                                <td className="px-4 py-3 text-start">
                                                    {template?.type?.toUpperCase()}
                                                    {template?.isSystem && <span className="text-gray-500"> (System)</span>}
                                                </td>
                                                <td className="px-4 py-3 text-start">
                                                    <div
                                                        className="cursor-pointer text-blue-600 gap-2  flex items-center"
                                                    >
                                                        <span data-tooltip-id={tooltipId}
                                                              data-tooltip-offset="14"
                                                              data-tooltip-place="right-start">
                                                                          <svg width="22" height="22"
                                                                               viewBox="0 0 22 22" fill="none"
                                                                               xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_341_11182)">
                                                                <path
                                                                    d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z"
                                                                    stroke="#37B34A" strokeWidth="1.5"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path
                                                                    d="M10.3125 10.3125C10.4948 10.3125 10.6697 10.3849 10.7986 10.5139C10.9276 10.6428 11 10.8177 11 11V14.4375C11 14.6198 11.0724 14.7947 11.2014 14.9236C11.3303 15.0526 11.5052 15.125 11.6875 15.125"
                                                                    stroke="#37B34A" strokeWidth="1.5"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path
                                                                    d="M10.6562 8.25C11.2258 8.25 11.6875 7.78829 11.6875 7.21875C11.6875 6.64921 11.2258 6.1875 10.6562 6.1875C10.0867 6.1875 9.625 6.64921 9.625 7.21875C9.625 7.78829 10.0867 8.25 10.6562 8.25Z"
                                                                    fill="#37B34A"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_341_11182">
                                                                    <rect width="22" height="22" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        </span>
                                                    </div>
                                                    <Tooltip
                                                        id={tooltipId}
                                                        clickable
                                                        style={{
                                                            borderRadius: 12,
                                                            padding: '12px',
                                                            background: 'white',
                                                            boxShadow: '0px 4px 8px 0px #5B68713D, 0px 0px 1px 0px #1A202452',
                                                            zIndex: 999
                                                        }}
                                                    >
                                                        <div className='max-w-[400px] text-black text-left'>
                                                            <div className="mb-2">
                                                                <strong>Full content:</strong>
                                                            </div>
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: template.content}}
                                                            ></div>
                                                        </div>
                                                    </Tooltip>
                                                </td>
                                                <td className="px-4 py-3">
                                                    <div className="flex gap-2 justify-end">
                                                        <Button className="bg-blue-500 text-white px-3 py-1 rounded"
                                                                onClick={() => handleEdit(template)}>
                                                            Edit
                                                        </Button>
                                                        <Button className="bg-red-500 text-white px-3 py-1 rounded"
                                                                onClick={() => handleDelete(template.id)}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col gap-[16px] mb-[32px]">
                        <h3 className="text-[20px] font-semibold">{isEditing ? "Edit" : "Create"} Template</h3>
                        <div className="flex max-md:flex-col md:items-start gap-[12px] md:gap-[16px] flex-1">
                            <label
                                className="flex items-center text-[16px] min-w-[140px] text-[16px] font-semibold text-zinc-800 min-h-[46px] leading-[18px]">
                                Type
                            </label>
                            <div className="flex flex-col gap-[8px] flex-auto">
                                <SelectBox
                                    options={types}
                                    svg={2}
                                    value={formData.type}
                                    onSelect={(value) => handleChange({target: {name: 'type', value}})}
                                    placeholder=''
                                />
                            </div>
                        </div>
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            errorClassName={"ml-[155px]"}
                            label="Title"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="title" id="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="Enter Title"
                            error={formErrors.title}
                        />
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Subject"
                            errorClassName={"ml-[155px]"}
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="subject" id="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            placeholder="Enter Subject"
                            error={formErrors.subject}
                        />
                        <div className="relative">
                            <TextareaFieldHorisontal
                                inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                label="Content"
                                errorClassName={"ml-[155px]"}
                                labelClassName="flex items-center min-w-[140px] text-[16px] md:min-h-[46px] font-semibold text-zinc-800 leading-[18px]"
                                type="text" name="content" id="content"
                                value={formData.content}
                                onChange={handleChange}
                                rows={4}
                                error={formErrors.content}
                            />
                            <Button
                                onClick={() => setIsVariablesVisible(!isVariablesVisible)}
                                className="mt-2 text-blue-600 underline hover:no-underline"
                            >
                                {isVariablesVisible ? "Hide Variables" : "Show Variables"}
                            </Button>
                            {isVariablesVisible && (
                                <div className="mt-2 border border-gray-300 rounded p-2 max-h-40 overflow-auto">
                                    {VARIABLES.map((v) => (
                                        <div key={v.key} className="mb-2">
                                            <strong>{`{{${v.key}}}`}</strong> - <span
                                            className="text-sm text-gray-600">{v.description}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                <Button
                    className={'text-gray-400 hover:opacity-70 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'}
                    onClick={handleCloseModal}
                >
                    <span className="underline">Cancel</span>
                </Button>
                <Button onClick={handleSubmit}
                        className={'bg-green-500 hover:opacity-70 text-white max-md:w-full min-w-[122px] h-[52px] text-[18px]'}>
                    {isLoading ? <PageLoader className='h-[40px] my-auto'/> :
                        <span>{isEditing ? "Update" : "Submit"}</span>}
                </Button>
            </div>
        </ModalTemplate>
    )
};

export default AddTemplateModal;
