import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import api from "../../api";
import {getAllComunicationsFetch, sendMessageToApplicantFetch} from "../../redux/thunks/communicationsThunks";
import Button from "../base/Button";
import InputFieldHorisontal from '../base/InputFieldHorisontal';
import ModalTemplate from '../base/ModalTemplate';
import PrimaryButton from "../base/PrimaryButton";
import SelectBox from "../base/SelectBox";
import TextareaFieldHorisontal from '../base/TextareaFieldHorisontal';
import CommunicationTable from "../../pages/SBM01/parts/CommunicationTable";
import PageLoader from "../PageLoader";
import {getSubscriberRole} from "../../redux/slices/applicationSlice";
import MultiSelect from "../base/MultiSelect";
import {toast} from "react-toastify";
import {isValidPhoneNumber} from "react-phone-number-input";

const SmsModal = ({
                      open,
                      handleClose,
                      tableHeding = false,
                      tableHedingButton = false,
                      footerBorderTop = true,
                      selectedApplicants = []
                  }) => {
    const dispatch = useDispatch();
    const {jobId} = useParams();
    const {isLoading: isCommunicationLoading} = useSelector(state => state.communications);
    const subscriberRole = useSelector(getSubscriberRole);

    const [jobData, setJobData] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [applicationData, setApplicationData] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [applicants, setApplicants] = useState(
        selectedApplicants.map(one => ({...one, value: one.phone, label: one.fullName}))
    );

    const applicationId = (selectedApplicants.length > 0 && selectedApplicants[0].id) || null;

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        content: '',
        templateId: ''
    });

    const getJobApplicationById = async () => {
        if (!jobId || !applicationId) return;
        setIsLoading(true);
        try {
            const jobRes = await api.jobs.getJobsById(jobId);
            setJobData(jobRes?.data);

            const res = await api.jobApplications.getJobApplicationById(jobId, applicationId);
            setApplicationData(res?.data);

            setFormData(prev => ({
                ...prev,
                name: res?.data?.fullName || '',
                phone: res?.data?.phone || '',
                email: res?.data?.email || '',
            }));

            const resTemplates = await api.communications.getAllTemplates(jobRes?.data?.companyId, "sms");
            setTemplates(resTemplates?.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getJobApplicationById();
    }, [jobId, applicationId]);

    useEffect(() => {
        if (!applicationId) return;
        dispatch(getAllComunicationsFetch({applicationId}));
    }, [applicationId]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setFormErrors(prevErrors => ({...prevErrors, [name]: ''}));
    };

    const handleSumbit = async () => {
        // if (!isValidPhoneNumber('+' + String(formData.phone))) {
        //     setFormErrors(prevState => ({...prevState, phone: "Invalid Phone"}))
        //     return
        // }

        if (!applicants || applicants.length === 0) {
            toast.error("No applicants selected.");
            return;
        }
        if (!formData.content) {
            setFormErrors(prevState => ({...prevState, content: "Content empty"}))
            return
        }

        if (applicants.length > 1) {
            await Promise.all(applicants.map(async (one) => {
                return dispatch(sendMessageToApplicantFetch({
                    applicationId,
                    body: {
                        type: "sms",
                        content: formData.content,
                        templateId: formData.templateId,
                        name: one.fullName,
                        phone: one.phone,
                        email: one.email
                    }
                }));
            }));
            toast.success("Message sent to all selected applicants successfully");
            handleClose();
            return;
        }

        const res = await dispatch(sendMessageToApplicantFetch({
            applicationId,
            body: {
                type: "sms",
                content: formData.content,
                templateId: formData.templateId,
                name: formData.name,
                phone: formData.phone,
                email: formData.email
            }
        }));
        if (res) {
            toast.success("Message sent successfully");
            setFormData(prev => ({...prev, content: ''}));
            handleClose();
        }
        dispatch(getAllComunicationsFetch({applicationId}));
    };

    const selectTemplate = (value) => {
        setFormData(prevState => ({...prevState, templateId: value}));
        const content = templates.find(one => one.id === value)?.content;
        if (content) {
            setFormData(prevState => ({...prevState, content: content}));
        }
    };

    return (
        <ModalTemplate open={open} onClick={handleClose} modalSize="max-w-[772px]">
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">SMS</h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            {
                !isLoading ?
                    <>
                        <div className="modal__body">
                            <div className="w-full">
                                <div className="flex flex-col gap-[16px] mb-[32px]">
                                    {
                                        selectedApplicants.length === 1 && (
                                            <>
                                                <InputFieldHorisontal
                                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                                    label="Name"
                                                    labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                                    type="text" name="name" id="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="John Doe"
                                                    error={formErrors.name}
                                                />
                                                <InputFieldHorisontal
                                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                                    label="Phone Number"
                                                    labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                                    type="text" name="phone" id="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    error={formErrors.phone}
                                                    placeholder="123-453-1232"
                                                />
                                                <InputFieldHorisontal
                                                    disabled={true}
                                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                                    label="Email"
                                                    labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                                    type="text" name="email" id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="example@mail.com"
                                                />
                                            </>
                                        )
                                    }

                                    {
                                        selectedApplicants.length > 1 && (
                                            <div className="flex items-center gap-[14px]">
                                                <p className="flex items-center min-w-[140px] text-[16px] md:min-h-[46px] font-semibold text-zinc-800 leading-[18px]">
                                                    Applicants
                                                </p>
                                                <div className="select-multi-value-container--secondary">
                                                    <MultiSelect
                                                        options={selectedApplicants.map(one => ({
                                                            ...one,
                                                            label: one.fullName,
                                                            value: one.phone
                                                        }))}
                                                        selectStyle="secondary"
                                                        placeholder='Add applicants'
                                                        value={applicants}
                                                        onChange={val => {
                                                            setApplicants(val);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div
                                        className="flex max-md:flex-col md:items-center gap-[12px] md:gap-[16px] flex-1">
                                        <label
                                            className="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]">
                                            Template
                                        </label>
                                        <SelectBox
                                            options={
                                                templates
                                                    ? templates.map(one => ({
                                                        label: `${one.title} ${one?.isSystem ? "(System)" : ""}`,
                                                        value: one.id
                                                    }))

                                                    : []
                                            }
                                            onSelect={selectTemplate}
                                            value={formData.templateId}
                                        />
                                    </div>

                                    <TextareaFieldHorisontal
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Content"
                                        labelClassName="flex items-center min-w-[140px] text-[16px] md:min-h-[46px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="content" id="content"
                                        value={formData.content}
                                        onChange={handleChange}
                                        error={formErrors.content}
                                        rows={4}
                                    />
                                </div>
                                {selectedApplicants.length === 1 && (
                                    <>
                                        {tableHeding && (
                                            <div className="flex items-center justify-between gap-2">
                                                <div
                                                    className="text-[18px] md:text-[20px] font-semibold leading-8 text-zinc-800">
                                                    Communication History
                                                </div>
                                                {tableHedingButton && (
                                                    <Button
                                                        className={'text-white bg-green-500 md:min-w-[122px] min-h-[52px] text-[18px]'}>
                                                        Dial
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                        {selectedApplicants?.length === 1 && selectedApplicants?.[0]?.activeClarficationAgent?.status === "active" && (
                                            <div className="text-yellow-500">
                                                Warning: This candidate is currently in the clarification process.
                                            </div>
                                        )}
                                        <div className="flex flex-col mt-[16px] gap-[32px]">
                                            {/*{data.map(dataItem =>*/}
                                            <div className="flex flex-col self-stretch max-h-[300px]">
                                                {/*<h2 className="w-full text-[18px] font-semibold text-stone-500 max-md:max-w-full mb-[10px]">*/}
                                                {/*    {dataItem.name}*/}
                                                {/*</h2>*/}
                                                <CommunicationTable isSorted={false} type="sms"/>
                                            </div>
                                            {/*)}*/}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {subscriberRole !== "interviewer" &&
                            <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                                <Button
                                    className={'text-gray-400 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'}
                                    onClick={handleClose}>
                                    <span className="underline">Cancel</span>
                                </Button>
                                <PrimaryButton
                                    className={'w-[122px] !h-[52px] !text-[18px]'}
                                    onClick={handleSumbit}
                                    isLoading={isCommunicationLoading}
                                >
                                    Submit
                                </PrimaryButton>
                            </div>}
                    </>
                    :
                    <PageLoader/>
            }
        </ModalTemplate>
    );
};

export default SmsModal;
