import React, {useState, useRef, useEffect} from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import {Footer} from '../components/parts/Footer'
import BlueHedingTable from '../components/base/BlueHedingTable'
import AddUserModal from "../components/modals/AddUserModal"
import {SuccessModal} from '../components/modals/SuccessModal';
import {useSelector} from 'react-redux';
import {getUser, getUserCompanyId} from '../redux/slices/applicationSlice';
import api from '../api';
import PageLoader from '../components/PageLoader';
import {useNavigate} from 'react-router-dom';
import AddTemplateModal from "../components/modals/AddTemplateModal";
import CreateTagModal from "../components/modals/CreateTagModal";

const SBM05_ADM = () => {
    const [addUser, setAddUser] = useState(false)
    const [addTemplate, setAddTemplate] = useState(false)
    const [addTags, setAddTags] = useState(false)
    const [successPopup, setSuccesPopup] = useState(false)
    const user = useSelector(getUser);
    const companyId = useSelector(getUserCompanyId);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const addItem = (item) => {
        setData(prev => ([...prev, {
            id: item.id,
            name: item.user.firstName,
            email: item.user.email,
            role: item.role,
            confirm: item.isVerified,
            editable: {
                edit: `/SBM05_1/${item.id}`
            },
            userId: item.userId || item.user.id
        }]))
    }
    const deleteItem = (id) => {
        setData(prev => prev.filter(item => item.id !== id))
    }
    const updateItem = (updatedItem) => {
        setData(prev => prev.map(item => item.id === updatedItem.id ? {
            ...item, ...{
                id: updatedItem.id,
                name: updatedItem.user.firstName,
                email: updatedItem.user.email,
                role: updatedItem.role,
                confirm: updatedItem.isVerified,
                editable: {
                    edit: `/SBM05_1/${updatedItem.id}`
                },
                userId: updatedItem.userId
            }
        } : item))
    }
    useEffect(() => {
        if (!user || !companyId) return;
        if(user.companies[0].role === 'member') {
            navigate('/SBM05_TEA')
        }
        setIsLoading(true)
        api.companyTeam.getTeamMembers(companyId).then(response => {
            if (!response.success) {
                return
            }
            setData(response.data.map(item => ({
                id: item.id,
                name: item.user.firstName,
                email: item.user.email,
                role: item.role,
                companyId,
                confirm: item.isVerified,
                editable: {
                    edit: `/SBM05_1/${item.id}`
                },
                userId: item.userId
            })))
        }).finally(() => {
            setIsLoading(false)
        });
    }, [user])


    return (
        <div className='flex-auto flex flex-col'>
            <BreadCrumbWhite
                crumbs={['Subscriber Dashboard', 'Admin User']}
                statusSelect={true}
                onTeamUserClick={() => setAddUser('member')}
                teamUser={true}
                onInterviewUserClick={() => setAddUser('interviewer')}
                interviewUser={true}
                templatesClick={() => setAddTemplate(true)}
                tagsClick={() => setAddTags(true)} />
            <section className='py-[50px] px-[20px] md:py-[90px] flex-auto'>
                <div className="max-w-[1400px] mx-auto">
                    {
                        !isLoading && user ?
                            <BlueHedingTable tableData={data} companyId={companyId} deleteItem={deleteItem} updateItem={updateItem} />
                            : <PageLoader />
                    }
                </div>
            </section>
            {addUser && <AddUserModal
                onSubmitClick={() => setSuccesPopup(true)}
                handleClose={() => setAddUser(false)}
                open={addUser}
                role={addUser}
                addItem={addItem}
            />}
            {addTemplate && <AddTemplateModal
                onSubmitClick={() => setSuccesPopup(true)}
                handleClose={() => setAddTemplate(false)}
                open={addTemplate}
            />}
            {addTags && <CreateTagModal
                onSubmitClick={() => setSuccesPopup(true)}
                handleClose={() => setAddTags(false)}
                open={addTags}
            />}
            {successPopup && <SuccessModal open={successPopup} handleClose={() => setSuccesPopup(false)}/>}
            <Footer hideCopyright={true}/>
        </div>
    )
}

export default SBM05_ADM
