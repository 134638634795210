const companiesBase = 'api/companies';

export const companies = (instance) => ({
    async getCompaniesList(payload) {
        const {data} = await instance.get(`${companiesBase}/all`);
        return data;
    },
    async getAllCompanies(payload) {
        const {data} = await instance.get(`${companiesBase}`);
        return data;
    },
    async getCompanyPublicInfo(companyId, payload) {
        const {data} = await instance.get(`${companiesBase}/p/${companyId}`);
        return data;
    },
    async getCompanyInfoByMemberAccess(companyId, payload) {
        const {data} = await instance.get(`${companiesBase}/${companyId}`);
        return data;
    },
    async updateCompanyInfo(companyId, payload) {
        const {data} = await instance.put(`${companiesBase}/${companyId}`, payload.body);
        return data;
    },
    async deleteCompany(companyId, payload) {
        const {data} = await instance.delete(`${companiesBase}/${companyId}`);
        return data;
    },


    async getAllIndustries() {
        const {data} = await instance.get(`api/industries`);
        return data;
    },
    async createDemoApplication(payload) {
        const {data} = await instance.post(`api/demo-applications`, payload)
        return data;
    },
    async getBuildCompanies(token) {
        const {data} = await instance.get(`api/build-companies/by-token/${token}`)
        return data;
    },

    async createBuildCompanies(token, payload) {
        const {data} = await instance.post(`api/build-companies/create-company/${token}`, payload)
        return data;
    },
    async createBuildCompaniesBySupport(payload) {
        const {data} = await instance.post(`api/build-companies/create-company-by-support`, payload)
        return data;
    },
    async createBuildCompaniesByAdmin(payload) {
        const {data} = await instance.post(`api/build-companies`, payload)
        return data;
    },
    async getAllCompaniesBySupport(payload) {
        const {data} = await instance.get(`api/companies/all?${payload}`)
        return data;
    },
    async getCompanyByIdSupport(payload) {
        const {data} = await instance.get(`api/companies/support/${payload}`)
        return data;
    },
    async getCompanyDemoByIdSupport(payload) {
        const {data} = await instance.get(`api/demo-applications/${payload}`)
        return data;
    },
    async getCompanyRequestByIdSupport(payload) {
        const {data} = await instance.get(`api/build-companies/${payload}`)
        return data;
    },
    async getUpdateCompanyByIdSupport(payload) {
        const {data} = await instance.put(`api/companies/support/${payload.id}`, payload.body)
        return data;
    },
    async getApproveCompanyByIdSupport(id, payload) {
        const {data} = await instance.post(`api/build-companies/approve-request/${id}`, payload)
        return data;
    },
    async getUpdateCompanyRequestByIdSupport(id, payload) {
        const {data} = await instance.put(`api/build-companies/${id}`, payload)
        return data;
    },
    async getDeactivateCompanyByIdSupport(payload) {
        const {data} = await instance.get(`api/users/deactivate-change/${payload.id}?isDeactivate=${payload.isDeactivate}`)
        return data;
    },

    async updateBuildCompanies(id, payload) {
        const {data} = await instance.put(`api/companies/${id}`, payload)
        return data;
    },
    async planCompanyInfo(id) {
        const {data} = await instance.get(`api/subs/${id}`)
        return data;
    },
    async getAllPricingPlans() {
        const {data} = await instance.get(`api/pricing-plans`)
        return data;
    },
    async cancelPricingPlan(id) {
        const {data} = await instance.post(`api/subs/cancel/${id}`)
        return data;
    },
    async selectPricingPlan(id, planId) {
        const {data} = await instance.post(`api/subs/${id}/${planId}`)
        return data;
    },
    async getAllDemoCompanies(payload) {
        const {data} = await instance.get(`api/demo-applications?${payload}`)
        return data;
    },
    async getAllCompaniesRequest(payload) {
        const {data} = await instance.get(`api/build-companies?${payload}`)
        return data;
    },
    async getApplicantsTags(companyId) {
        const {data} = await instance.get(`api/applicant-tags/${companyId}`);
        return data
    },

    async createApplicantsTags(payload) {
        const {data} = await instance.post(`api/applicant-tags`, payload)
        return data;
    },
    async editApplicantsTags(id ,payload) {

        console.log("id", id, "payload", payload)

        const {data} = await instance.put(`api/applicant-tags/${id}`, payload)
        return data;
    },
    async removeApplicantsTag(Id) {
        const {data} = await instance.delete(`api/applicant-tags/${Id}`, )
        return data;
    },
});

