import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import ModalTemplate from "../base/ModalTemplate";
import Button from "../base/Button";
import PageLoader from "../PageLoader";
import api from "../../api";
import {getUserCompanyId} from "../../redux/slices/applicationSlice";
import {toast} from "react-toastify";

const OptionList = ({el, removeAction, editAction = false, change}) => {
    const [edit, setEdit] = useState(editAction);
    const [value, setValue] = useState(el.name || "");
    const editableOptionRef = useRef(null);

    useEffect(() => {
        if (edit) {
            editableOptionRef.current?.focus();
        }
    }, [edit]);

    return (
        <li className="mb-2 pb-2 border-b border-b-neutral-200 flex items-center justify-between max-md:flex-col">
            <div
                className="flex items-center gap-3 max-md:justify-between max-md:w-full max-md:mb-[20px] min-w-[350px]">
                <input
                    className="text-5 bg-transparent focus:outline-none min-w-[300px]"
                    ref={editableOptionRef}
                    value={value}
                    disabled={!edit}
                    onChange={(e) => setValue(e.target.value)}
                    // onBlur={() => change(value)}
                    placeholder="New Tag"
                />
            </div>
            <div className="flex items-center gap-3">
                {!edit ? (
                    <button className="hover:opacity-70" onClick={() => setEdit(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path
                                d="M8.16797 19.848L13.3165 19.8305L24.5538 8.70048C24.9948 8.25948 25.2375 7.67381 25.2375 7.05081C25.2375 6.42781 24.9948 5.84215 24.5538 5.40115L22.7035 3.55081C21.8215 2.66881 20.2826 2.67348 19.4076 3.54731L8.16797 14.6796V19.848ZM21.0538 5.20048L22.9076 7.04731L21.0445 8.89298L19.1941 7.04381L21.0538 5.20048ZM10.5013 15.6526L17.5363 8.68415L19.3866 10.5345L12.3528 17.5006L10.5013 17.5065V15.6526Z"
                                fill="#BEBEBE"/>
                            <path
                                d="M5.83333 24.5H22.1667C23.4535 24.5 24.5 23.4535 24.5 22.1667V12.054L22.1667 14.3873V22.1667H9.51767C9.48733 22.1667 9.45583 22.1783 9.4255 22.1783C9.387 22.1783 9.3485 22.1678 9.30883 22.1667H5.83333V5.83333H13.8215L16.1548 3.5H5.83333C4.5465 3.5 3.5 4.5465 3.5 5.83333V22.1667C3.5 23.4535 4.5465 24.5 5.83333 24.5Z"
                                fill="#BEBEBE"/>
                        </svg>
                    </button>
                ) : (
                    <button
                        className="bg-[#37B34A] text-white py-[5px] px-[10px] rounded-md hover:opacity-70"
                        onClick={() => {
                            setEdit(false);
                            change(value);
                        }}
                    >
                        Save
                    </button>
                )}
                <button
                    className="bg-[#D70000] text-white py-[5px] px-[10px] rounded-md hover:opacity-70"
                    onClick={removeAction}
                >
                    Remove
                </button>
            </div>
        </li>
    );
};


const CreateTagModal = ({open, handleClose, footerBorderTop = true}) => {
    const companyId = useSelector(getUserCompanyId);

    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (companyId) fetchTags();
    }, [companyId]);

    const fetchTags = async () => {
        try {
            const resTags = await api.companies.getApplicantsTags(companyId);
            setList(resTags?.data || []);
        } catch (e) {
            console.error("Failed to fetch tags:", e);
        }
    };

    const handleAddTag = async (value) => {
        setIsLoading(true);
        try {
            const response = await api.companies.createApplicantsTags({companyId, name: value});
            if (response.success) {
                fetchTags();
                toast.success("Tag added successfully");
            } else {
                toast.error(response.message || "Failed to add tag");
            }
        } catch (error) {
            console.error("Error adding tag:", error);
        }
        setIsLoading(false);
    };

    const handleEditTag = async (tag, value) => {

        console.log("tag", tag)
        if (!tag?.id || !value?.trim()) return;

        setIsLoading(true);
        try {
            const response = await api.companies.editApplicantsTags(tag?.id, {name: value});
            if (response.success) {
                fetchTags();
                toast.success("Tag updated successfully");
            } else {
                toast.error(response.message || "Failed to update tag");
            }
        } catch (error) {
            console.error("Error updating tag:", error);
        }
        setIsLoading(false);
    };

    const handleSaveTag = (item, value) => {
        if (!value) {
            toast.error("Please enter a valid tag name");
        };
        if (item.id) {
            handleEditTag(item, value)
        } else {
            handleAddTag(value)
        }
    };

    const handleDeleteTag = async (item, idx) => {

        console.log("idx", idx)

        const tag = list[idx];
        console.log("tag", tag)
        if (!tag?.id) {
            setList((prev) => prev.filter((_, i) => i !== idx));
            return;
        }
        ;


        setIsLoading(true);
        try {
            const response = await api.companies.removeApplicantsTag(tag.id);
            if (response.success) {
                setList((prev) => prev.filter((one) => one.id !== item.id));
                toast.success("Tag deleted successfully");
            } else {
                toast.error(response.message || "Failed to delete tag");
            }
        } catch (error) {
            console.error("Error deleting tag:", error);
        }
        setIsLoading(false);
    };

    const handleCloseModal = () => {
        handleClose();
    };

    return (
        <ModalTemplate open={open} onClick={handleCloseModal}>
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">Tags</h2>
                <button className="btn btn-close" onClick={handleCloseModal}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                <div className="w-full">
                    <div className="flex flex-col gap-[16px] mb-[10px] overflow-auto max-h-[250px] pr-2">
                        <ul>
                            {list.map((el, idx) => (
                                <OptionList
                                    key={el.id}
                                    el={el}
                                    idx={idx}
                                    editAction={el.actionEdit}
                                    removeAction={() => handleDeleteTag(el, idx)}
                                    change={(value) => handleSaveTag(el, value)}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="mt-[20px] w-full justify-end flex pr-2">
                        <a
                            href="#"
                            className="text-7 font-bold text-[#37B34A] underline hover:opacity-70"
                            onClick={(e) => {
                                e.preventDefault();
                                setList((prev) => [...prev, {title: '', actionEdit: true}]);
                            }}
                        >
                            Add a New Tag
                        </a>
                    </div>
                </div>
            </div>
            <div className={`modal__footer ${footerBorderTop ? "modal__footer-border" : ""}`}>
                <Button className="text-gray-400 hover:opacity-70 max-md:w-full min-w-[122px] h-[52px]"
                        onClick={handleCloseModal}>
                    <span className="underline">Cancel</span>
                </Button>
                <Button onClick={handleCloseModal}
                        className="bg-green-500 hover:opacity-70 text-white max-md:w-full min-w-[122px] h-[52px] text-[18px]">
                    <span>Submit</span>
                </Button>
            </div>
        </ModalTemplate>
    );
};

export default CreateTagModal;

