import React, {useState, useEffect, useLayoutEffect} from 'react';
import BreadCrumbWhite from "../../components/BreadCrumbWhite";
import {HiringProcess} from '../../components/base/ProgressStepsSBM';
import ContactForm from '../../components/parts/ContactForm';

import StepsComponents from "./steps";
import api from "../../api";
import PageLoader from "../../components/PageLoader";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../redux/slices/applicationSlice";
import {toast} from 'react-toastify';
import Confirmation from "../../components/Confirmation";

const NextStepSection = ({back, next, backShow = true, nextShow = true, nextDisabled = false, create}) => {
    return (
        <section>
            {/* mt-9  */}
            <hr className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full"/>
            <div
                className="flex gap-5 justify-between self-stretch mt-[36px] w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                {backShow ? <a href="#" onClick={(e) => {
                    e.preventDefault();
                    back && back();
                }}
                               className="flex items-center justify-center px-12 h-[52px] bg-gray-400 rounded-md max-md:px-5 max-md:flex-1">
                    Back
                </a> : null}
                {nextShow ? <a href="#" onClick={(e) => {
                    e.preventDefault();
                    next && next();
                }}
                               className={`flex items-center justify-center px-16 h-[52px] bg-green-500 rounded-md max-md:px-5 max-md:flex-1 ml-[auto] ${nextDisabled ? 'disabled' : ''}`}>
                    Next
                </a> : <a href="#" onClick={(e) => {
                    e.preventDefault();
                    create && create();
                }}
                          className={`flex items-center justify-center px-16 h-[52px] bg-green-500 rounded-md max-md:px-5 max-md:flex-1 ml-[auto]`}>
                    Save Job
                </a>}
            </div>
        </section>
    );
};


const NewJobDetails = () => {
    const navigate = useNavigate();
    const me = useSelector(getUser);

    const StepsComponentsArray = [StepsComponents.Step1, StepsComponents.Step2, StepsComponents.Step3, StepsComponents.Step4, StepsComponents.Step7, StepsComponents.Step8];
    const breadcrumbsInit = ["Description", "Preview", "Pipeline", "Application Form", "Disposition Status"];

    const breadcrumbs = [[], ...new Array(7).fill("").map((item, index) => new Array(index + 1).fill("")).map(item => item.map((item2, index2) => item2 = [breadcrumbsInit[index2], '', () => {
        setCurrentStep(index2 + 2);
        setContactFormOpen(false);
        setContactFormOpenSended(false);
    }]))];

    const [steps, setSteps] = useState([
        {stepNumber: "1", label: "New Job Details", isActive: true},
        {stepNumber: "2", label: "Description", isActive: false},
        {stepNumber: "3", label: "Preview", isActive: false},
        {stepNumber: "4", label: "Pipeline", isActive: false},
        {stepNumber: "5", label: "Application Form", isActive: false},
        {stepNumber: "6", label: "Disposition Status", isActive: false},
    ]);

    const [currentStep, setCurrentStep] = useState(1);

    const Step = StepsComponentsArray[currentStep - 1];

    const changeStep = (type = 'next') => {
        if (type === 'next' && currentStep !== 6) {
            setCurrentStep((prev) => (++prev));
        } else if (type === 'back' && currentStep !== 1) {
            setCurrentStep((prev) => (--prev));
        }
    }

    const [payload, setPayload] = useState({
        jobTitle: 'Software Engineer',
        timezone: 'GMT+3',
        manageRole: 'Senior Manager',
        employeeType: 'Full-time',
        remoteJob: true,
        country: 'US',
        address: '123 Main Street',
        city: 'Valencia',
        state: 'CA',
        zipCode: '12345',
        unemployment_rate_at: '5.3',
        ftm_pay_rate_for_level_1: '1.14',
        ftm_pay_rate_for_level_7: '1.51',
        description: 'Join the Clean Team as a  Chemical Free Cleaning Technician at FreshTechMaids and let\'s turn cleaning into a sparkling adventure!\n' +
            '\n' +
            'At FreshTechMaids.com, our Chemical Free Cleaning Technicians are serious about cleaning and hardwork... but we also have serious fun! We believe in making cleaning a blast while providing top-notch service to our clients.\n' +
            '\n' +
            'Pay: $18.50 to $24.50 per cleaning hour, with a $100 Sign-On Bonus and $600 Quarterly Performance Bonus. Plus, you keep all your tips!\n' +
            '\n' +
            'No experience? No problem! We\'ll turn you into a cleaning ninja in no time with our great 4-week training program.\n' +
            '\n' +
            'Requirements for a Chemical Free Cleaning Technician:\n' +
            '\n' +
            '  (1) Fluent in English with excellent communication skills.\n' +
            '  (2) Commitment to a drug-free lifestyle.\n' +
            '  (3) Ability to pass a criminal background check.\n' +
            '  (4) Authorized to work and reside in the United States.\n' +
            '  (5) Reliable transportation, either via Public Transportation or a vehicle that is insured. The company pays for your monthly CTA pass and supports your gas cost if you drive.\n' +
            '  (6) Empathetic nature and positive attitude.\n' +
            '  (7) Capacity to lift up to 30 pounds and stand for extended periods.\n' +
            '\n' +
            'Why Join Us as a Chemical Free Cleaning Technician?\n' +
            '\n' +
            '  (1) Zero to Hero: We\'ll train you from "I-don\'t-even-own-a-duster" to "Cleaning Connoisseur" in record time.\n' +
            '  (2) Cha-Ching! : Competitive pay with the potential to earn enough to buy that gold-plated vacuum you\'ve been eyeing! We also pay for a monthly CTA bus pass for our technicians who utilize public transportation and support your gas costs if you utilize your vehicle.\n' +
            '  (3) No Gym? No problem! : Cleaning is a physical job that gets your body moving throughout the day, no need to pay the gym membership fee! Cleaning is hard work, but you can lose weight while working, time just passes quickly!\n' +
            '  (4) Rise and Shine: Climb the ranks from Dust Buster to Cleaning Crusader with our seven levels of technician program.The highest pay rate is $24.50 per cleaning hour!"\n' +
            '  (5) Flexibility: We support your life goals with scheduling flexibility and moral support! You do not fight for your goals alone, because life\'s too short to spend all your time cleaning (unless you\'re getting paid for it)!\n' +
            '  (6) No Sneaky Fees: We\'ve got you covered from mop to bucket with zero supplies and equipment fees, so you can focus on the cleaning magic.\n' +
            '  (7) Tip Top: Keep every penny of those tips – your piggy bank will thank you!\n' +
            '  (8) Safety First: Free PPE to keep you protected from dust storms and soap suds.\n' +
            '  (9) Team Spirit: Join a crew that\'s tighter than a perfectly tucked bedsheet, with regular events to keep the good times rolling.\n' +
            '\n' +
            'Benefits\n' +
            ' \n' +
            '  (1) Quarterly events\n' +
            '  (2) Company-sponsored health insurance\n' +
            '  (3) Company-sponsored IRA\n' +
            '  (4) Paid time off\n' +
            '  (5) Free CTA Bus Pass or up to $40 per week gas subsidy, depending on the location of your residence.\n' +
            '  (6) Free home cleaning by your wonderful team members.\n' +
            '  (7) $100 sign on bonus\n' +
            '  (8) Quarterly Performance Bonus: $600',
        industrieId: 1,
        pipelineId: 1,
        qaPresetId: 1,
        applicationForm: {
            it_takes_15_minutes_active: true,
            it_takes_15_minutes_title: "It takes 15 minutes!",
            it_takes_15_minutes_text: "",
            what_you_need_active: true,
            what_you_need_title: "What you need:",
            what_you_need_text: ["Resume", "Reference", "Contact Details"],

            how_long_it_takes_active: true,
            how_long_it_takes_title: "How long it takes?",
            how_long_it_takes_text: "It takes 15 minutes to complete this application.  Very important to copy and paste your resume.  This might be the most important 15-minute for your future!",

            consent_active: true,
            consent_title: "Consent",
            consent_text: "Please ensure that you are aware of and agree to the following critical requirements:",

            are_you_able_to_legally_work_in_the_united_state_active: false,
            are_you_able_to_legally_work_in_the_united_state_title: "Are you able to legally work in the United States?",
            are_you_able_to_legally_work_in_the_united_state_choices: ["Yes", "No"],

            how_did_you_hear_about_this_job_active: true,
            how_did_you_hear_about_this_job_title: "How did you hear about this job?",
            how_did_you_hear_about_this_job_choices: [
                "Referral from friends",
                "Google",
                "Indeed",
                "Facebook",
                "Snag A Job",
                "Craigslist",
                "Care.com",
                "Culinary Agent",
                "Drop By Location"
            ],
            please_review_the_following_terms_disclosures_and_consent_active: true,
            please_review_the_following_terms_disclosures_and_consent_title: "Please review the following Terms, Disclosures and Consent",
            please_review_the_following_terms_disclosures_and_consent_text: "By checking this box and clicking the Save & Continue’ button below, I confirm that i read, agree, and consent to the information described in the link(s) below:",
            please_review_the_following_terms_disclosures_and_consent_files_1: "/uploads/job-attachments/10c15cbb-abd6-480b-b6de-a0a89dee2e7f_1727990430483_incl.pdf",
            please_review_the_following_terms_disclosures_and_consent_files_2: "/uploads/job-attachments/8b31be38-5bda-400b-ad28-951daf12bf53_1727990430486_incl.pdf",
            please_review_the_following_terms_disclosures_and_consent_files_3: false,
            please_review_the_following_terms_disclosures_and_consent_files_text: [
                "Company E Signiture Consent Sept 2024.pdf",
                "EmployJoy Employer Terms of Service Sept2024.pdf"
            ],
            please_review_the_following_terms_disclosures_and_consent_files_names: [
                "Company E Signiture Consent Sept 2024.pdf",
                "EmployJoy Employer Terms of Service Sept2024.pdf"
            ],
            please_review_the_following_terms_disclosures_and_consent_yes_i_have_read: "Yes, I have read and agree with the above terms.",

            you_yes_you_active: true,
            you_yes_you_title: "You! Yes, You!",
            you_yes_you_text: "Please share the following information with us!",
            education_active: true,
            education_title: "Education",
            education_high_school_title: "High School:",
            education_high_school_name: "School Name",
            education_high_school_city: "City",
            education_high_school_state: "State",
            education_high_school_postal_code: "Postal Code",
            education_high_school_did_you_graduate_text: "Did you graduate",
            education_high_school_did_you_graduate_choices: ["Yes", "No"],

            education_college_school_title: "College:",
            education_college_school_name: "School Name",
            education_college_school_city: "City",
            education_college_school_state: "State",
            education_college_school_postal_code: "Postal Code",
            education_college_school_did_you_graduate_text: "Did you graduate",
            education_college_school_did_you_graduate_choices: ["Yes", "No", "Degree", "Major"],

            most_recent_employment_history_active: true,
            most_recent_employment_history_title: "Most Recent Employment History",
            most_recent_employment_history: [
                {
                    city: "City",
                    phone: "Phone",
                    state: "State",
                    address: "Address",
                    job_title: "Job Title",
                    supervisor: "Supervisor",
                    postal_code: "Postal Code",
                    company_name: "Company Name",
                    reason_for_leaving: "Reason for Leaving",
                    list_your_responsibilities: "List your responsibilities:"
                }
            ],

            may_we_contact_your_previous_supervisor_for_reference_active: true,
            may_we_contact_your_previous_supervisor_for_reference_title: "May we contact your previous supervisor for reference?",
            may_we_contact_your_previous_supervisor_for_reference_choices: ["Yes", "No"],

            what_is_the_maximum_number_of_employers_an_applicant_can_enter_active: true,
            what_is_the_maximum_number_of_employers_an_applicant_can_enter_title: "# of References - the More, the Better!",
            what_is_the_maximum_number_of_employers_an_applicant_can_enter_choices: [1, 2, 3],
            what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_1: "First Professional Reference Full Name, Phone Number, and the Company Name where you worked with the reference.",
            what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_2: "Second Professional Reference Full Name, Phone Number, and the Company Name where you worked with the reference.",
            what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_3: "Third Professional Reference Full Name, Phone Number, and the Company Name where you worked with the reference.",

            name_of_emergency_contact_active: true,
            name_of_emergency_contact_title: "Name of Emergency Contact",
            name_of_emergency_contact_full_name: "Full Name",
            name_of_emergency_contact_email: "Email",
            name_of_emergency_contact_phone_number: "Phone Number",
            name_of_emergency_contact_relationship: "Relationship",

            do_you_have_any_relative_who_is_an_employee_of_this_company_active: true,
            do_you_have_any_relative_who_is_an_employee_of_this_company_title: "Do you have any relative who is an employee of this company?",
            do_you_have_any_relative_who_is_an_employee_of_this_company_choices: ["Yes", "No"],
            do_you_have_any_relative_who_is_an_employee_of_this_company_if_so_who: "If so, who?",

            did_someone_refer_you_to_the_position_active: true,
            did_someone_refer_you_to_the_position_title: "Did someone refer you to the position?",
            did_someone_refer_you_to_the_position_choices: ["Yes", "No"],
            did_someone_refer_you_to_the_position_if_so_who: "If so, who?",

            did_someone_refer_you_to_the_position_the_source_you_heard_about_this_job_active: false,
            did_someone_refer_you_to_the_position_the_source_you_heard_about_this_job: "The source you heard about this job?",

            how_many_jobs_have_you_help_wihin_the_last_two_years_active: false,
            how_many_jobs_have_you_help_wihin_the_last_two_years_title: "How many jobs have you held in the last two years?",
            how_many_jobs_have_you_help_wihin_the_last_two_years_choices: ["1", "2", "3", "4"],

            have_you_ever_been_terminated_from_a_job_active: false,
            have_you_ever_been_terminated_from_a_job_title: "Have you ever been terminated from a job?",
            have_you_ever_been_terminated_from_a_job_choices: ["Yes", "No"],
            have_you_ever_been_terminated_from_a_job_if_so_please_explain: "If so, please explain",

            critical_requirements_of_the_job_active: true,
            critical_requirements_of_the_job_title: "Critical Requirements of the Job",
            critical_requirements_of_the_job_text: "Please answer honestly so that we can find the right fit for you and for us! We want to have win win!",
            critical_requirements_of_the_job_questions: {},

            your_availability_for_work_active: true,
            your_availability_for_work_title: "Your Availability for Work",
            your_availability_for_work_sunday: "Sunday",
            your_availability_for_work_sunday_available_beginning_at: "8:30 AM",
            your_availability_for_work_sunday_available_ending_at: "8:30 AM",
            your_availability_for_work_monday: "Monday",
            your_availability_for_work_monday_available_beginning_at: "8:30 AM",
            your_availability_for_work_monday_available_ending_at: "8:30 AM",
            your_availability_for_work_tuesday: "Tuesday",
            your_availability_for_work_tuesday_available_beginning_at: "8:30 AM",
            your_availability_for_work_tuesday_available_ending_at: "8:30 AM",
            your_availability_for_work_wednesday: "Wednesday",
            your_availability_for_work_wednesday_available_beginning_at: "8:30 AM",
            your_availability_for_work_wednesday_available_ending_at: "8:30 AM",
            your_availability_for_work_thursday: "Thursday",
            your_availability_for_work_thursday_available_beginning_at: "8:30 AM",
            your_availability_for_work_thursday_available_ending_at: "8:30 AM",
            your_availability_for_work_friday: "Friday",
            your_availability_for_work_friday_available_beginning_at: "8:30 AM",
            your_availability_for_work_friday_available_ending_at: "8:30 AM",
            your_availability_for_work_saturday: "Saturday",
            your_availability_for_work_saturday_available_beginning_at: "8:30 AM",
            your_availability_for_work_saturday_available_ending_at: "8:30 AM",

            what_is_your_earliest_date_to_start_active: true,
            what_is_your_earliest_date_to_start_title: "What is your earliest date to start?",
            what_is_your_earliest_date_to_start_month: "Month",
            what_is_your_earliest_date_to_start_day: "Day",
            what_is_your_earliest_date_to_start_year: "Year",

            you_must_copy_and_paste_your_resume_here_active: true,
            you_must_copy_and_paste_your_resume_here_title: "You must copy and paste your resume here",
            you_must_copy_and_paste_your_resume_here_text: "Failure to attach your resume may results in immediate failure of your application.",
            you_must_copy_and_paste_your_resume_here_please_copy_and_paste: "Please copy and paste your resume in the text box below:",
            you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached: "Alternatively, you can also attached the PDF version of your resume here:",
            you_must_copy_and_paste_your_resume_here_linkedin_profile_url: "LinkedIn Profile URL:",


            the_next_step_active: true,
            the_next_step_title: "The Next Step",
            the_next_step_text: "Thank you for submitting your application. Your time investment on this process is appreciated!",
            the_next_step_you_should_hear_title: "You should hear back from us in 2 business days",
            the_next_step_you_should_hear_text: "Please do make sure that you have submitted or copy/paste your resume."
        },
        // dispositionStatus: ["Too far for vehicle owner", "Too far for public transportation", "Sensitive to travel"],
        dispositionStatus: null,
        attachments: [],
        files_map: {},
        status: "active",
        mainInfo: {
            whyWorkWithUsTitle: "Why work with us as a Chemical Free Home Cleaning Technician?",
            whyWorkWithUsItems: [
                {
                    title: "No Experience Necessary",
                    description: "No experience? No problem! We train you from day one. You’ll also learn many helpful tips and tricks along the way."
                },
                {
                    title: "Awesome Pay",
                    description: "Pay starts at $18.50 per cleaning hour after training, with room to grow to $24.50."
                },
                {
                    title: "Amazing Growth Potential",
                    description: "We have seven levels of Cleaning Technician. We reward your great performance with higher pay and bonuses."
                },
                {
                    title: "Life Friendly Scheduling",
                    description: "Whether you’re a parent or a student, we work to accommodate your schedule. We offer part time, half time, and full time positions."
                },
                {
                    title: "Regular Hours",
                    description: "Say goodbye to crazy shifts and irregular hours. We will never ask you to clean on weekends or holidays, and your job will never stray beyond 8 AM to 5 PM."
                },
                {
                    title: "Tips Are 100% Yours",
                    description: "Our maids earn an average of $2,500 in tips each year. The best part? You don’t have to tell us about it.",
                },
                {
                    title: "Safety is #1",
                    description: "Your health is our first priority. That’s why we supply you with free PPE and ensure safety via all our operating processes."
                },
                {
                    title: "A Company that Truly Cares",
                    description: "We are not a referral service or franchise. As a locally owned and operated business in Chicago, we put our people first."
                },
                {
                    title: "Fantastic Team Members",
                    description: "We love who we work with, and you will too. Get to know your colleagues at our weekly meetings and fun quarterly events."
                }
            ],
            qualificationsTitle: "Qualifications",
            qualificationsItems: [
                "Amazing Attitude",
                "Loves Physical Work. Can lift 30lbs",
                "Fluent English",
                "Drug Free Lifestyle",
                "Authorized to Work in US"
            ],
            locationRequisitesTitle: "Location Requisites",
            locationRequisitesDescription: "Ready to scrub, sparkle, and shine your way into the hearts of our clients? Apply now to be a Chemical Free Cleaning Technician and let's make cleaning legendary together at FreshTechMaids.com!",
            locationRequisitesItems: [
                {
                    title: "Arlington Heights",
                    description: `– Must own car \n– Live within 15 miles`
                },
                {
                    title: "Chicago",
                    description: `– Own car or use public transportation \n– Live within 1 hour of our Chicago headquarters by public transport`
                }
            ]
        }
    });

    const getRejectionReasons = async () => {
        const res = await api.jobApplications.getRejectionReasons();
        if (res?.data) {
            setPayload({
                ...payload,
                dispositionStatus: res?.data,
            });
        }
    };


    useEffect(() => {
        if (payload?.dispositionStatus?.length > 0 && typeof payload?.dispositionStatus[0] === 'object') return
        getRejectionReasons();
    }, [payload?.dispositionStatus]);



    const [industriesList, setIndustriesList] = useState([]);


    const [contactFormOpen, setContactFormOpen] = useState(false);
    const [contactFormOpenSended, setContactFormOpenSended] = useState(false);

    const [qaPresetAttributesSelected, setQaPresetAttributesSelected] = useState(null);
    const [loading, setLoading] = useState(false);


    const getIndustriesList = async (id) => {
        let res = await api.jobs.getIndustriesList();

        setIndustriesList(res.data)
    }

    const nextDisabled = currentStep === 4 && !payload.pipelineId;

    const createJob = async () => {
        setLoading(true);
        console.log('job payload before:', payload);

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                please_review_the_following_terms_disclosures_and_consent_files_1: null,
                please_review_the_following_terms_disclosures_and_consent_files_2: null,
                please_review_the_following_terms_disclosures_and_consent_files_3: null,
            }
        });

        var data = new FormData();

        data.append("jobTitle", payload.jobTitle);
        data.append("timezone", payload.timezone);
        data.append("manageRole", payload.manageRole);
        data.append("employeeType", payload.employeeType);
        data.append("remoteJob", payload.remoteJob);
        data.append("country", payload.country);
        data.append("address", payload.address);
        data.append("city", payload.city);
        data.append("state", payload.state);
        data.append("zipCode", payload.zipCode);
        data.append("unemployment_rate_at", payload.unemployment_rate_at);
        data.append("ftm_pay_rate_for_level_1", payload.ftm_pay_rate_for_level_1);
        data.append("ftm_pay_rate_for_level_7", payload.ftm_pay_rate_for_level_7);
        data.append("description", payload.description);
        data.append("industrieId", payload.industrieId);
        data.append("pipelineId", payload.pipelineId);
        data.append("qaPresetId", payload.qaPresetId);
        data.append("applicationForm", JSON.stringify(payload.applicationForm));
        data.append("dispositionStatus", JSON.stringify(payload.dispositionStatus));
        data.append("status", payload.status);
        data.append("files_map", JSON.stringify(payload.files_map));
        data.append("mainInfo", JSON.stringify(payload.mainInfo));

        for (let index = 0; index < payload.attachments.length; index++) {
            data.append("attachments", payload.attachments[index], payload.attachments[index].name);
        }

        let res = await api.jobs.createJobToCompany({
            companyId: me.company.id,
            rest: data
        });
        setLoading(false);

        if (res?.success) {
            navigate("/jobs-setup");
            toast.success(res?.message || "Job created successfully");
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    }


    useEffect(() => {
        setSteps((prev) => {
            return [...prev].map((item, index) => {
                return index <= currentStep - 1 ? {...item, isActive: true} : {...item, isActive: false}
            })
        });

        setContactFormOpen(false);
    }, [currentStep]);

    useEffect(() => {
        if (qaPresetAttributesSelected) {
            setPayload({
                ...payload,
                applicationForm: {
                    ...payload.applicationForm,
                    // critical_requirements_of_the_job_questions: qaPresetAttributesSelected.attributes.map(item => item.questions).flat()
                }
            })
        }
    }, [qaPresetAttributesSelected]);

    useEffect(() => {
        getIndustriesList();
    }, []);

    useEffect(() => {
        if (contactFormOpenSended) {
            setTimeout(() => {
                setContactFormOpenSended(false);
                setContactFormOpen(false);
            }, 3000);
        }
    }, [contactFormOpenSended]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [currentStep])

    const getQaPresetAttributesListByIndustryId = async (id) => {
        let res = await api.jobs.getQaPresetAttributesListByIndustryId(payload.industrieId);
        const item = res.data?.[0];
        setPayload({...payload, qaPresetId: item.id || 1});
        setQaPresetAttributesSelected(item);
    }

    useEffect(() => {
        if (payload?.industrieId) {
            getQaPresetAttributesListByIndustryId(payload.industrieId)
        }
    }, [payload?.industrieId]);

    return (
        <>
            <BreadCrumbWhite
                crumbs={[
                    'Subscriber Dashboard', ['Job Setup', '/jobs-setup'], ["New Job Details", '', () => {
                        setCurrentStep(1);
                    }], ...breadcrumbs[currentStep - 1],
                    ...(contactFormOpen && !contactFormOpenSended ? ['Contact Us'] : contactFormOpenSended ? ['Contact Us', 'Thank You'] : [])
                ]}
            />
            {!industriesList?.length ? <PageLoader/> : !contactFormOpen ?
                <div className="flex flex-col gap-[60px] max-w-[1432px] px-4 w-full pt-[24px] lg:pt-14 pb-24 mx-auto">
                    <section className="flex w-full text-center overflow-auto py-4">
                        <HiringProcess
                            stepsData={steps}
                            stepClick={(value) => {
                                setCurrentStep(value);
                            }}
                        />
                    </section>

                    <Step
                        payload={payload}
                        setPayload={setPayload}
                        setContactFormOpen={setContactFormOpen}
                        industriesList={industriesList}
                        qaPresetAttributesSelected={qaPresetAttributesSelected}
                        setQaPresetAttributesSelected={setQaPresetAttributesSelected || null}
                    />

                    <NextStepSection
                        next={() => {
                            changeStep('next')
                        }}
                        back={() => {
                            changeStep('back')
                        }}
                        backShow={currentStep !== 1}
                        nextShow={currentStep !== 6}
                        nextDisabled={nextDisabled}
                        create={() => !loading && createJob()}
                    />
                </div> : contactFormOpenSended ?
                    <Confirmation/> :
                    <ContactForm
                        companyId={me?.company?.id}
                        setContactFormOpen={setContactFormOpen}
                        setContactFormOpenSended={setContactFormOpenSended}
                        areas={steps[currentStep - 1].label}
                    />
            }
        </>
    )
}

export default NewJobDetails;
